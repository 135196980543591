import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Navbar from './components/navbar';
import Home from './components/home';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        
        <Switch>
        <Route exact path="/" component={Home} />
        <Route component={Home} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
