import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <div className="App">

        <nav className="navbar navbar-expand-lg navbar navbar-light">
        <Link to={'/'} className="navbar-brand" >WebCitizen</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item active"> 
                <Link to={'/'} className="nav-link" href="#">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                <a href="http://emmani.vercel.app/" target="_blank" rel="noopener noreferrer" className="nav-link">Portfolio</a>
                </li>
            </ul>
        </div>
        </nav>

    </div>
  );
}

export default Navbar;
