import React, {useEffect} from 'react';
// import { Link } from 'react-router-dom';
// import {Animated} from "react-animated-css";
import Aos from "aos";
import "aos/dist/aos.css";

// import phone from './img/phone.png';
import envelope from './img/envelope.png';

import modern from './img/modern.png';
import custom from './img/custom.png';
import cost from './img/cost.png';
import social from './img/social.png';

import wordpress from './img/wordpress.png';
import custom2 from './img/custom2.png';
import survey from './img/survey.png';

import becky from './img/becky.jpg';
import estate from './img/estate.jpg';
import ola from './img/ola.jpg';
import bio from './img/bio.jpg';

import facebook from './img/facebook.png';
import linkedIn from './img/linkedin.png';
import youtube from './img/youtube.png';


function Home() {
  // AOS INITIALISE
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <div className="Home" id="up">


      <div className='AreaOne'>
        {/* <Animated animationIn="bounceInDown" animationOut="fadeOut" isVisible={true}>
          <div id="alert1" className="alert alert-primary" role="alert">
            Not taking new clients at the moment.
          </div>
        </Animated> */}
        <h1>WEBCITIZEN</h1>
        <h5>WEB DEVELOPMENT & DESIGN</h5>
        <a href="mailto:web4citizen@gmail.com" className="Onebutton">CONTACT</a>

        <div className="contactInfo">
          {/* <p><img alt="phone" src={phone}></img>+27 685864064</p> */}
          <p><img alt="envelope" src={envelope}></img>web4citizen@gmail.com</p>
        </div>
      </div>


      <div className="AreaTwo">
      <h1>NEED A WEBSITE?</h1>

        <div className="twoIcons">
          <div className="iconType">
            <img alt="phone" src={modern}></img>
            <p data-aos="zoom-in-up">Modern Design</p>
          </div>

          <div className="iconType">
            <img alt="phone" src={custom}></img>
            <p data-aos="zoom-in-up">Custom Offering</p>
          </div>

          <div className="iconType">
            <img alt="phone" src={cost}></img>
            <p data-aos="zoom-in-up">Cost Effective</p>
          </div>
          
          <div className="iconType">
            <img alt="phone" src={social}></img>
            <p data-aos="zoom-in-up">Media Plugins</p>
          </div>
        </div>

        <div className="twoExcerpt">
          <div className="excerptText">
            <h4>Take your presence online & showcase your products & services to a broader audience. All at an affordable cost.</h4>
          </div>

          <div className="excerptText">
            <h4>Need a custom website? We'll help you set up everything! Your website will be designed to your specifications.</h4>
          </div>

          <div className="excerptText">
            <h4>Stand out with a landing page. We'll help create an amazing exhibition platform for your product.</h4>
          </div>
        </div>
      </div>


      <div className="AreaThree">
        <div className="threeHead">
          <h1>Packages & Services</h1>
        </div>

        <div className="threePackages">

          <div className="offers">
            <h3 data-aos="fade-up"><img alt="react" src={custom2}></img>Custom Website</h3>
            <ul>
              <li>Responsive & Modern Design</li>
              <li>Smooth User Experience</li>
              <li>Multi-platform Compatible</li>
              <li>Custom Designed Tab icon</li>
              <li>Performance Optimization</li>
              <li>MySQL Database Storage</li>
              <li>Login & Contact Form</li>
              <li>Google Maps plugin</li>
              <li>Social Media Share</li>
              <li>Comment Section API</li>
              <li>Site Source Code</li>
              {/* <li>from N$ 599</li> */}
            </ul>
          </div>

          <div className="offers">
            <h3 data-aos="fade-up"><img alt="wordpress" src={wordpress}></img>WordPress</h3>    
            <ul>
              <li>Clean User Interface Design</li>
              <li>Content Management Panel</li>
              <li>Custom Made Tab Icon</li>
              <li>Social-Media plugins</li>
              <li>Performance Optimization</li>
              <li>Google Maps plugin</li>
              <li>Contact Forms</li>
              <li>Email Marketing plugin</li>
              <li>Security & Anti-Spam plugins</li>
              <li>Search Engine Optimization</li>
              <li>System Back-up plugins</li>
              {/* <li>from N$ 450</li> */}
            </ul>
          </div>

          <div className="offers">
            <h3 data-aos="fade-up"><img alt="survey" src={survey}></img>Online Survey</h3>
            <ul>
              <li>Webpage to Conduct Survey</li>
              <li>Up to 500 Daily Respondents</li>
              <li>Online Database and Email</li>             
              <li>A Data Management Panel</li>
              <li>Contact forms plugin</li>
              <li>Security & Anti-Spam</li>
              <li>Social Media Share</li>
              <li>Google location Maps</li>
              <li>Data Management Panel</li>
              <li>File Management Features</li>
              <li className="remove" style={{opacity:"0"}}>W3C Validated</li>
              {/* <li>from N$450</li> */}
            </ul>
          </div>
        </div>

        <div className="threeMore">
          <div className="moreTitle">
            {/* <h1><br></br>In Addition...</h1> */}
          </div>
          <div className="moreText">
            <hr></hr><ul>           
              <li>Each package offering will be customized to suit your needs.</li>
              <li>Free Hosting Service, Monthly Maintenance & Support Available.</li>
              <li>Custom Domains & Annual Hosting Services Available with SSL Certificate.</li>
              <li>Have any questions? Feel free to reach out at: web4citizen@gmail.com</li>
            <hr></hr></ul>
          </div>
        </div>
      </div>


      <div className="AreaFour">
        <div className="fourHead">
          <h1>Latest Projects</h1>
        </div>

        <div className="fourProject">
          <div className="project">
          <a href="http://emmani.vercel.app/" target="_blank" rel="noopener noreferrer"><h5>VIEW PORTFOLIO</h5></a>
          <br/>
          <div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={ola} className="d-block w-100" alt="estate"></img>
                <div className="carousel-caption d-none d-md-block">
                  <h5>Medical Practice Website</h5>
                </div>
              </div>
              <div className="carousel-item">
                <img src={becky} className="d-block w-100" alt="becky"></img>
                <div className="carousel-caption d-none d-md-block">
                  <h5>Becky Boutique Retailer</h5>
                </div>
              </div>
              <div className="carousel-item">
                <img src={estate} className="d-block w-100" alt="estate"></img>
                <div className="carousel-caption d-none d-md-block">
                  <h5>Real Estate Website</h5>
                </div>
              </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
            </div>
            <hr></hr>
          </div>

          <div className="about">
            <h6><img alt="bio" src={bio}></img><hr></hr>Hello there! I'm [Emmanuel] a professional
             developer with expertise in web applications. Detail your requirements and I'll build you
             a modern product with great user experience.<hr></hr></h6>
          </div>
        </div>
      </div>


      <div className="footer">
        <div className="smediaFooter">
            <p>Connect:</p>
            <a href="https://www.facebook.com/Webcitizen-107799590843196/" target="_blank" rel="noopener noreferrer"><img src= {facebook} alt="fb"></img></a>
            <a href="https://www.youtube.com/channel/UCAqwXZJOxBOBC6ijtOu75VQ/featured?view_as=subscriber" target="_blank" rel="noopener noreferrer"><img src= {youtube} alt="linkedIn"></img></a>
            <a href="https://www.linkedin.com/in/emmanuel-ogundiran-a8330414a/" target="_blank" rel="noopener noreferrer"><img src= {linkedIn} alt="youtube"></img></a>
            {/* <a href="https://www.peopleperhour.com/freelancer/development-it/emmanuel-ogundiran-web-developer-xmannym#offers" target="_blank" rel="noopener noreferrer"><img src= {pph} alt="pph"></img></a> */}
        </div>
        <div className="webName">
            <a href="#up">Back to top</a>
        </div>
      </div>

    </div>
  );
}

export default Home;
